import { useLocation, useSearchParams } from "@remix-run/react";
import React from "react";
import { cache } from "~/utils/cache";
import { Spinner } from "../format/Spinner";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import type { Thread } from "~/utils/leocache";
import { SmallAvatar12 } from "../format/SmallAvatar";
import Reputation from "../format/Reputation";
import DisplayName from "../format/DisplayName";
import AccountName from "../format/AccountName";
import { TimeSincePublish } from "../TimeSincePublish";
import { ThreadBody, ThreadFooter, ThreadText } from "./ThreadViewHelpers";
import { useParsedBody } from "~/utils/markdown";
import { useCanComment, useDecryption } from "~/hooks/useDecryption";
import { ThreadActions } from "./ThreadActions";
import ReplyCount from "../format/ReplyCount";
import type { HiveContent } from "~/utils/hive";
import { fetchReplies } from "~/utils/hive";
import { Virtuoso } from "react-virtuoso";
import { ThreadView } from "./ThreadView";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, faTimes } from "@fortawesome/free-solid-svg-icons";
import { cn } from "~/utils/cn";
import { DecryptLoader } from "../general/DecryptLoader";
import { SubscribeToCreatorThread } from "../general/SubscribeToCreator";
import ThreadsEditor from "./ThreadsEditor";

export default function ThreadQuickView() {
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const thread = searchParams.get("view");

  const [content, setContent] = React.useState<Thread | null>(location.state?.thread);
  const index = location.state?.index ?? 0;

  function closeQuickView() {
    setSearchParams(
      params => {
        params.delete("view");
        return params;
      },
      {
        preventScrollReset: true
      }
    );
  }

  // keybinds
  React.useEffect(() => {
    if (!thread) return;

    function handleKeydown(event: React.KeyboardEvent) {
      switch (event.key) {
        case "Escape": {
          closeQuickView();
          break;
        }
      }
    }

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, []);

  // disable overflow
  React.useEffect(() => {
    if (thread) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [thread]);

  // fetch content
  React.useEffect(() => {
    if (content) return;

    if (!thread) {
      closeQuickView();
      return;
    }

    const [author, permlink] = [thread.split("/")[0], thread.split("/")[1]];

    if (!author || !permlink) {
      closeQuickView();
      return;
    }

    void (async function () {
      try {
        const thread = await cache.getContent({ author, permlink });

        setContent(thread as unknown as Thread);
      } catch {
        closeQuickView();
      }
    })();
  }, [thread]);

  return (
    <div aria-modal="true" className="fixed inset-0 size-full bg-black/95 z-[1002]">
      <button
        type="button"
        className="fixed top-4 left-4 flex justify-center items-center size-8 rounded-full bg-white/5 dark:bg-white/10 border border-pri/10 dark:border-pri-d/50 text-white cursor-pointer z-[2] transition-colors duration-150 hover:bg-white/15"
        onClick={() => closeQuickView()}
      >
        <FontAwesomeIcon icon={faTimes} size="sm" fixedWidth />
      </button>

      <div className="relative flex flex-col flex-1 size-full z-[1]">
        {content ? (
          <QuickThreadView thread={content} index={index} closeQuickView={closeQuickView} />
        ) : (
          <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
            <Spinner className="fill-white text-white/10" />
          </span>
        )}
      </div>
    </div>
  );
}

function QuickThreadView({
  thread,
  index,
  closeQuickView
}: {
  thread: Thread;
  index: number;
  closeQuickView: Function;
}) {
  const scrollerRef = React.useRef<HTMLDivElement>(null);

  const encrypted = thread.json_metadata.encrypted ?? false;

  const { decryptedBody, decrypting, decryptionFailed, images } = useDecryption(thread, encrypted, true);
  const canComment = useCanComment(thread.author, encrypted);
  const parsedBody = useParsedBody(decryptedBody);

  const isMobile = useMediaQuery("(max-width: 640px)");
  const [collapsed, setCollapsed] = React.useState(isMobile);

  const [fakeComment, setFakeComment] = React.useState(null);

  React.useEffect(() => {
    const { width } = document.documentElement.getBoundingClientRect();

    const isMobile = width < 640;

    if (collapsed !== isMobile) {
      setCollapsed(isMobile);
    }
  }, []);

  const pushToStart = setFakeComment;

  return (
    <div className="flex flex-1 flex-row size-full">
      <ImageView images={images} index={index} closeQuickView={closeQuickView} />

      <button
        type="button"
        className={cn(
          "fixed right-4 top-4 size-8 flex justify-center items-center bg-white/5 dark:bg-white/10 border border-pri/10 dark:border-pri-d/50 rounded-full text-white hover:bg-white/15 transition-all duration-300 z-[999]",
          {
            "translate-x-0 sm:-translate-x-96": !collapsed
          }
        )}
        onClick={() => setCollapsed(c => !c)}
      >
        <Chevrons collapsed={collapsed} />
      </button>

      <div
        ref={scrollerRef}
        className={cn(
          "relative flex flex-col w-full sm:w-96 shrink-0 h-full pt-10 sm:pt-0 bg-pri dark:bg-pri-d sm:border-l border-pri dark:border-pri-d overflow-y-auto z-10 transition-all duration-300",
          {
            "w-0 sm:w-0 translate-x-full": collapsed
          }
        )}
      >
        <div className="flex flex-col w-full gap-y-4 p-4">
          <header className="flex flex-row items-center w-full gap-x-3">
            <div className="relative">
              <SmallAvatar12 author={thread.author} disableThreadcastIndicator />

              <div className="absolute -bottom-0.5 -right-0.5">
                <Reputation reputation={thread.author_reputation} />
              </div>
            </div>

            <div className="flex flex-row mt-1">
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-2">
                  <DisplayName
                    authorName={thread.author}
                    name={thread.display_name}
                    premium={thread.is_premium}
                    subscriptions={thread.subscriptions}
                  />
                </div>

                <div className="flex flex-row items-center gap-1 pt-px">
                  <AccountName author={thread.author} />
                  <span className="flex text-gray-600 dark:text-gray-400">·</span>
                  <TimeSincePublish publishTime={thread.created} utc={false} />
                </div>
              </div>
            </div>
          </header>

          <ThreadBody>
            <ThreadText className="!text-base">
              {decrypting ? (
                <DecryptLoader />
              ) : decryptionFailed ? (
                <SubscribeToCreatorThread creator={thread.author} />
              ) : (
                parsedBody
              )}
            </ThreadText>
          </ThreadBody>

          <ThreadFooter className="relative w-full justify-between">
            <ReplyCount threadContent={thread} replyCount={thread.children} />
            <ThreadActions content={thread} />
          </ThreadFooter>
        </div>

        <div className="flex flex-col w-full border-t border-pri dark:border-pri-d">
          {canComment ? (
            <div className="flex w-full h-fit">
              <ThreadsEditor type={2} pushToStart={pushToStart} className="h-fit" />
            </div>
          ) : null}

          {/* comments */}
          <Comments scroller={scrollerRef} thread={thread} fakeComment={fakeComment} />
        </div>
      </div>
    </div>
  );
}

function Comments({
  scroller,
  thread,
  fakeComment
}: {
  scroller: React.RefObject<HTMLDivElement>;
  thread: Thread;
  fakeComment: any;
}) {
  const [replies, setReplies] = React.useState<HiveContent[] | null>(null);

  React.useEffect(() => {
    const fetchRepliesData = async () => {
      try {
        const fetchedReplies = (await fetchReplies({ author: thread.author, permlink: thread.permlink })) || [];
        // repliesLoaded = repliesLoaded.filter(
        //   x => !ignoredUsers?.includes(x?.author)
        // );

        // const repliesParsed = repliesLoaded;

        setReplies(fetchedReplies || []);
      } catch (error) {
        console.error("Error fetching replies:", error);
      }
    };

    fetchRepliesData();
  }, [thread]);

  React.useEffect(() => {
    if (!fakeComment) return;

    setReplies(replies => {
      if (replies) {
        return [...replies, fakeComment];
      }

      return [fakeComment];
    });
  }, [fakeComment]);

  if (replies && replies.length > 0) {
    return (
      <Virtuoso
        customScrollParent={scroller.current!}
        className="flex flex-col z-[0] relative"
        style={{ height: "400px" }}
        data={replies}
        totalCount={replies?.length || 0}
        computeItemKey={(i, item) => item.permlink || i}
        itemContent={(i, threadContent) => {
          return <ThreadView threadContent={threadContent} noParent={true} display="quick" />;
        }}
      />
    );
  }

  if (replies === null) {
    return (
      <div className="flex justify-center items-center w-full py-6">
        <Spinner className="fill-black dark:fill-white" />
      </div>
    );
  }

  return (
    <span className="flex justify-center items-center text-center py-6 text-sm text-pri/50 dark:text-pri-d/50">
      No comments yet.
    </span>
  );
}

function ImageView({ images, index, closeQuickView }: { images: string[]; index: number; closeQuickView: Function }) {
  const viewer = React.useRef(null);
  const [currentIndex, setCurrentIndex] = React.useState(index);
  const [direction, setDirection] = React.useState(0);
  const [isInitialRender, setIsInitialRender] = React.useState(true);

  React.useEffect(() => {
    setIsInitialRender(true);
  }, []);

  const nextImage = () => {
    setDirection(1);
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    setIsInitialRender(false);
  };

  const prevImage = () => {
    setDirection(-1);
    setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
    setIsInitialRender(false);
  };

  React.useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === "ArrowRight") {
        nextImage();
      } else if (ev.key === "ArrowLeft") {
        prevImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? "200%" : "-200%"
        // opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? "200%" : "-200%"
        // opacity: 0
      };
    }
  };

  return (
    <div className="flex flex-1 shrink h-full">
      <div className="relative flex flex-col justify-center items-center w-full h-full z-10">
        <div className="relative flex flex-col justify-center items-center w-full h-full">
          <span className="absolute inset-0 size-full z-0" onClick={() => closeQuickView()} />

          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              variants={isInitialRender ? {} : variants}
              initial={isInitialRender ? "center" : "enter"}
              animate="center"
              exit="exit"
              transition={{
                ease: "linear",
                x: { type: "spring", stiffness: 300, damping: 50 },
                opacity: { duration: 0.3 }
              }}
              className="absolute flex justify-center items-center max-w-full z-10"
            >
              <div className="relative block overflow-hidden z-10">
                <img
                  ref={viewer}
                  src={images[currentIndex]}
                  alt=""
                  className="w-auto h-auto max-h-[calc(100vh-4rem)] max-w-full object-center object-contain"
                  loading="lazy"
                />
              </div>
            </motion.div>
          </AnimatePresence>

          {/* <div className="absolute flex justify-center items-center max-w-full z-10">
            <div className="relative block overflow-hidden z-10">
              <img
                ref={viewer}
                src={images[currentIndex]}
                alt=""
                className="w-auto h-auto max-h-[calc(100vh-4rem)] max-w-full object-center object-contain"
                loading="lazy"
              />
            </div>
          </div> */}
        </div>

        {currentIndex !== 0 && images.length > 1 ? (
          <button
            onClick={prevImage}
            className="absolute left-5 top-1/2 -translate-y-1/2 flex items-center justify-center w-9 h-9 rounded-full bg-white/5 dark:bg-white/10 border border-pri/10 dark:border-pri-d/50 hover:bg-white/10 dark:hover:bg-white/15 transition-colors z-10"
          >
            <FontAwesomeIcon icon={faArrowLeftLong} className="text-white" />
          </button>
        ) : null}

        {currentIndex !== images.length - 1 && images.length > 1 ? (
          <button
            onClick={nextImage}
            className="absolute right-5 top-1/2 -translate-y-1/2 flex items-center justify-center w-9 h-9 rounded-full bg-white/5 dark:bg-white/10 border border-pri/10 dark:border-pri-d/50 hover:bg-white/10 dark:hover:bg-white/15 transition-colors z-10"
          >
            <FontAwesomeIcon icon={faArrowRightLong} className="text-white" />
          </button>
        ) : null}
      </div>
    </div>
  );
}

function Chevrons({ collapsed }: { collapsed: boolean }) {
  return (
    <span className={cn("transition-transform duration-150", { "rotate-180": collapsed })}>
      <svg
        viewBox="0 0 24 24"
        width="18"
        height="18"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="13 17 18 12 13 7" />
        <polyline points="6 17 11 12 6 7" />
      </svg>
    </span>
  );
}

